
import Vue from "vue";
import moment from "moment";

export default Vue.extend({
    name: "BillingSummary",

    props: {
        user: {
            type: Object,
            required: true
        }
    },

    data: () => ({
        history: [] as Array<any>,
        loadings: {
            loadingHistory: true as boolean,
        } as any
    }),

    async mounted() {
        // await this.loadPaymentHistory();
    },

    methods: {
        async loadPaymentHistory() {
            this.loadings.loadingHistory = true;
            try {
                const response = await this.$API.billing().plansHistory(this.user.id);
                if(Array.isArray(response))
                {
                    let res = response.map((item: any) => ({
                      ...item,
                      _expanded: false
                    }));

                    this.history = this.getOpenAndFailedInvoices(res);
                }
            } catch (e) {
                await this.$store.dispatch("alert/showError", e.message);
            }
            this.loadings.loadingHistory = false;
        },

        getOpenAndFailedInvoices(results: any)
        {
          return results.filter((item:any) => {
            return item.number !== null;
          })
        }
    }
});
