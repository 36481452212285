
import Vue from "vue";

export default Vue.extend({
    name: "CreditScoreHistory",

    props: {
        creditHistory: {
            required: true,
            type: [] as Array<any>,
            default: []
        },
        user: {
            type: Object,
            required: true
        }
    },

    computed: {
        canDelete(){
            return this.$can('delete','CreditScore');
        }
    },

    methods: {
        async deleteCreditHistoryItem(id: number, index: number): Promise <any>{
            let response = await this.$API.user().deleteCreditScoreHistoryItem(id);
            if(response.success){
                this.creditHistory.splice(index, 1);
                await this.$store.dispatch('alert/showSuccess', response.message);
            }else{
                await this.$store.dispatch('alert/showError', response.message);
            }
        }
    }
});
