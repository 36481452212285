
import Vue from "vue";
import API from "@/api/API";
import store from "@/store";
import FileUploader from "@/components/form-components/FileUploader.vue";
import CreditScoreHistory from "@/modules/profile/components/credit-score/CreditScoreHistory.vue";
import BillingSummary from "@/modules/profile/components/BillingSummary.vue";

export default Vue.extend({
  name: "Profile",

  components: {
    CreditScoreHistory,
    FileUploader,
    BillingSummary,
    dashboard: () =>
      import(/* webpackChunkName: "Dashboard" */ "../components/Dashboard.vue"),
    billing: () =>
      import(/* webpackChunkName: "Billing" */ "../components/Billing.vue"),
    personal: () =>
      import(/* webpackChunkName: "Personal" */ "../components/Personal.vue"),
    chat: () => import(/* webpackChunkName: "Chat" */ "../components/Chat.vue"),
    security: () =>
      import(/* webpackChunkName: "Chat" */ "../components/Security.vue"),
    credit: () => import(/* webpackChunkName: "CreditScoreForm" */'../components/credit-score/CreditScoreForm.vue'),
    resources: () =>
      import(/* webpackChunkName: "Chat" */ "../components/Resources.vue"),
  },

  data: () => ({
    drawer: false as boolean,
    verificationStep: 2,
    user: null as any,
    dialog: false,
    avatar: null as any,
    showMenu: false,
    x: 0,
    y: 0,
    credentials: store.getters["authentication/credentials"],
    loading: false as boolean,
    feedbackDialog: false as boolean,
    managerFeedback: {
      stars: 0,
      message: ""
    },
    feedbackStatuses: ["Ужасно", "Плохо", "Нормально", "Хорошо", "Отлично"],
    timeout: null as any,
    stopLoadingUser: false as boolean,
    states: [] as Array<any>,
    stateName: "",
    creditHistory: [] as Array<any>,
    firstName: "" as string,
    lastName: "" as string,
  }),

  computed: {
    currentComponent() {
      return this.$options.components?.[this.$route.params.action];
    },
    isOwner() {
      return (
        !this.$route.params.id || this.user.id === this.credentials.user.id
      );
    },

    // Get the full address of the user
    fullAddress(){
      this.states.forEach((el) => {
        if(el.value === this.user.state_id)
          this.stateName = el.text;
      })
      let fullAddress = [];
      fullAddress[0] = this.user.address ?? '';
      fullAddress[1] = this.user.city ?? '';
      fullAddress[1] += this.stateName ? ", " + this.stateName : '';
      fullAddress[1] += this.user.zip_code ? ", " + this.user.zip_code : '';

      // return this.user.address + ", " + this.user.city + ", " + this.stateName + ", " + this.user.zip_code;
      return fullAddress;
    },

    // Format the SSN numbers by replacing the first chars with an asterix
    formattedSSN() {
      if(this.user.ssn)
        return '*'.repeat(5) + this.user.ssn.slice(-4);
    },
    formattedDate() {
      if (this.user.birthdate) {
        const parts = this.user.birthdate.split("-");
        if (parts.length === 3) {
          const [year, month, day] = parts;
          return `${month}/${day}/${year}`;
        }
      }
      return "";
    },

    displayUserName() {
      let userFirstName = "";
      let userLastName = "";
      if (this.user.name.includes('‎'))
      {
        userFirstName = this.user.name.split('‎')[0];
        userLastName = this.user.name.split('‎')[1];
      } else {
        userFirstName = this.user.name.split(' ')[0];
        userLastName = this.user.name.split(' ')[1];
      }
      return {
        "first_name": userFirstName,
        "last_name": userLastName
      };
    }
  },

  watch: {
    stopLoadingUser() {
      if (this.stopLoadingUser) {
        clearTimeout(this.timeout);
      } else {
        this.getUser();
      }
    },
  },

  async beforeRouteEnter(to, from, next) {
    try {
      const user = await API.user().profile(Number(to.params.id));

      next(vm => {
        vm.user = user;

        if (!vm.user.documents_verified_at || !vm.user.manager) {
          vm.getUser();
        }
      });
    } catch (e) {
      await store.dispatch("alert/showError", e.message);
    }
  },

  async beforeRouteUpdate(to, from, next) {
    await this.getUser(to.params.id);
    next();
  },

  mounted() {
    this.loadStates();
    this.getCreditHistory();
  },

  methods: {
    async getCreditHistory() {
      try{
        let res = await this.$API.user().getCreditHistory(this.user.id);
        if(res.success)
        {
          this.creditHistory = res.data;
        }
      }catch (e){
        await this.$store.dispatch("alert/showError", e.message);
      }
    },

    // Load states to get the name of the state by ID
    async loadStates() {
      try {
        this.states = await this.$API.location().getStates();
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },

    // Logout promise
    async logout(): Promise<void> {
      try {
        await this.$store.dispatch("authentication/logout");
        document.location.href = origin;
      } catch (e) {
        await this.$store.dispatch("alerts/showError", e.message);
      }
    },
    async changeAvatar(): Promise<void> {
      this.loading = true;
      try {
        this.user.avatar = await this.$API
          .user()
          .changeAvatar(this.user.id, { avatar: this.avatar });

        if (this.isOwner) {
          const user = this.$store.getters["user/info"];
          user.avatar = this.user.avatar;
          await this.$store.dispatch("user/set", user);
        }
        this.avatar = null;
        await this.$store.dispatch(
          "alert/showSuccess",
          "Аватар успешно измененм"
        );

        this.dialog = false;
      } catch (e) {
        await this.$store.dispatch("alerts/showError", e.message);
      }
      this.loading = false;
    },
    async deleteAvatar(): Promise<void> {
      try {
        this.user.avatar = await this.$API.user().deleteAvatar(this.user.id);

        if (this.isOwner) {
          const user = this.$store.getters["user/info"];
          user.avatar = this.user.avatar;
          await this.$store.dispatch("user/set", user);
        }

        this.avatar = null;
        await this.$store.dispatch(
          "alert/showSuccess",
          "Аватар успешно измененм"
        );

        this.dialog = false;
      } catch (e) {
        await this.$store.dispatch("alerts/showError", e.message);
      }
    },
    //todo rename
    show(e: any) {
      e.preventDefault();
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.showMenu = true;
      });
    },
    closeFeedback() {
      this.managerFeedback = {
        message: "",
        stars: 0
      };
      this.feedbackDialog = false;
    },
    openFeedback() {
      this.feedbackDialog = true;
      setTimeout(() => {
        (this.$refs.feedbackInput as Vue).focus();
      }, 300);
    },
    async sendFeedback() {
      try {
        await this.$API.user().sendFeedback(this.managerFeedback);
        this.closeFeedback();
        await this.$store.dispatch(
          "alert/showSuccess",
          "Ваш отзыв успешно отправлен! Спасибо что вы с нами!"
        );
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    async getUser(user = ""): Promise<void> {
      try {
        this.user = await this.$API
          .user()
          .profile(this.isOwner ? "" : user || this.user.id);
        if (
          !this.stopLoadingUser &&
          ((this.isOwner && !this.user.documents_verified_at) ||
            !this.user.manager)
        ) {
          this.timeout = setTimeout(() => {
            this.getUser();
          }, 60000);
        }
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
  }
});
